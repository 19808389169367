import React from 'react';

function Technics({ data, selectedTech, onTechChange }) {
    const handleClick = (event) => {
        const tech = event.currentTarget.getAttribute('data-tech');
        onTechChange(tech);
    };

    return (
        <div className="all-tech">
            {data.map(item => (
                <div
                    className={`tech-box ${selectedTech === item.name ? 'act' : ''}`}
                    key={item.ID}
                    data-tech={item.name}
                    onClick={handleClick}
                >
                    {item.name}
                </div>
            ))}
        </div>
    );
}

export default Technics;
