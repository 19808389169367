import React from 'react';

function Footer() {
    return (
        <footer>
            <div>&copy; Gallery Mart 2024</div>
        </footer>
    );
}

export default Footer;