import React from 'react';
import Gallery from './FirstGallery';

function Home({ toggleTextVisibility, isTextVisible }) {
    return (
        <div>
            <h1>Добро пожаловать в мою онлайн галерею.</h1>
            {/* <a onClick={toggleTextVisibility} className='open-text'>&#9776;</a> */}
            <div className='slide-box-two'>
                <div className='text'>
                    {/* <a onClick={toggleTextVisibility} className='close-text'>&#10006;</a> */}
                    <p>Рисование для меня – это не просто хобби, это целый мир, в котором я нахожу свое истинное "я". В каждый мазок, в каждую линию я вкладываю частичку своей души, выражая те эмоции и чувства, которые иногда сложно передать словами. Это искусство самовыражения, где каждая картина – это маленькая история, рассказанная безмолвными цветами и формами.</p>
                    <p>Несмотря на то, что рисую я всего несколько лет, это стало для меня чем-то большим, чем просто увлечение. Когда я держу кисть в руках, мир вокруг словно исчезает. Я погружаюсь в состояние, где нет места суете и стрессу. Это мой моральный отдых, время, когда я могу позволить себе быть просто собой, свободным от внешних обязательств и ожиданий. Рисование помогает мне физически абстрагироваться от повседневной жизни, находя покой и гармонию в каждом движении кисти или карандаша.</p>
                    <p>Для меня рисование – это еще и медитация. В процессе создания я нахожу внутренний баланс, умиротворение и глубокое понимание самого себя. Это время, когда я могу остановиться, услышать свои мысли и чувства, и дать им возможность проявиться на холсте. В каждый рисунок я вкладываю не только свои идеи, но и кусочек своей души, делая его уникальным и неповторимым.</p>
                    <p>Любовь к рисованию – это нечто большее, чем просто страсть. Это способ увидеть мир иначе, через призму творчества и воображения. Это возможность найти красоту в самых простых вещах и поделиться этой красотой с окружающими. В каждом рисунке я нахожу нечто новое и удивительное, и именно поэтому я люблю рисовать.</p>
                </div>
            </div>
            <div className='content'>
                    <Gallery></Gallery>
            </div>
        </div>
    );
}

export default Home;
