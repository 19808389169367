import React, { useState, useEffect } from 'react';
import LinkYear from './LinkYear';
import Gallery from './Gallery';
import Technics from './Technics';
import { baseUrl } from '../config/config';

function App() {

    const [dataY, setDataY] = useState([]);
    const [dataT, setDataT] = useState([]);
    const [dataG, setDataG] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedTech, setSelectedTech] = useState(null);

    useEffect(() => {
        fetchDataY();
        fetchDataT();
        fetchDataG();
    }, []);

    const fetchDataY = async () => {
        const response = await fetch(`${baseUrl}apics/api-year.php`);
        const jsonData = await response.json();
        setDataY(jsonData);
    };

    const fetchDataT = async () => {
        const response = await fetch(`${baseUrl}apics/api-tech.php`);
        const jsonData = await response.json();
        setDataT(jsonData);
    };

    const fetchDataG = async () => {
        const response = await fetch(`${baseUrl}apics/api-list.php`);
        const jsonData = await response.json();
        setDataG(jsonData);
    };

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    const handleTechChange = (tech) => {
        setSelectedTech(tech);
    };

    return (
        <div>
            <h1>Paintings</h1>
            <div className='content'>
                <div className='filter'>
                    <LinkYear data={dataY} onYearChange={handleYearChange} />
                    <Technics data={dataT} selectedTech={selectedTech} onTechChange={handleTechChange} />
                </div>
                <Gallery data={dataG} selectedYear={selectedYear} selectedTech={selectedTech} />
            </div>
        </div>
    );
}

export default App;
