import React, { useState } from 'react';

function LinkYear({ data, onYearChange }) {
    const [selectedYear, setSelectedYear] = useState(null);

    const handleClick = (event) => {
        const year = event.currentTarget.getAttribute('data-year');
        setSelectedYear(year);
        onYearChange(year);
    };

    return (
        <div className="all-year">
            {data.map(item => (
                <div
                    className={`year-box ${selectedYear === item.desc ? 'act' : ''}`}
                    key={item.ID}
                    data-year={item.desc}
                    onClick={handleClick}
                >
                    {item.name}
                </div>
            ))}
        </div>
    );
}

export default LinkYear;
