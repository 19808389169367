import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Portfolio from './components/Portfolio';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';


function App() {
    const [isTextVisible, setIsTextVisible] = useState(false);

    const toggleTextVisibility = () => {
        setIsTextVisible(!isTextVisible);
    };
    const baseUrl = "/";

    return (
        <Router>
            <NavBar toggleTextVisibility={toggleTextVisibility} />
            <Routes>
                <Route path="/" element={<Home toggleTextVisibility={toggleTextVisibility} isTextVisible={isTextVisible} />} />
                <Route path="/paintings" element={<Portfolio toggleTextVisibility={toggleTextVisibility} isTextVisible={isTextVisible} />} />
            </Routes>
            <Footer />
            <ScrollToTopButton />
        </Router>
    );
}

export default App;
