import React from 'react';
import { NavLink } from "react-router-dom";

function NavBar({ toggleTextVisibility }) {
    return (
        <nav>
            <a href="/"><img src="/img/logo.png"/></a>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/paintings">Paintings</NavLink>
                </li>
                <li className="nav-item">
                    <a className='telegram' target='_blank' href="https://t.me/GalleryMart_Bot"></a>
                </li>
            </ul>
        </nav>
    );
}

export default NavBar;
